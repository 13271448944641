.overlay {
  z-index: 50;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
.hero-details {
  position: fixed;
  z-index: 200;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 2rem auto;
  max-width: min(90%, 40rem);
  /* height: fit-content; */
  max-height: 90vh;
}
