.card {
  position: relative;
}
.card-title {
  pointer-events: none;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.5rem 1rem;
}
.card-body {
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 1rem;
  padding: 0;
}
.good:hover {
  outline: 1px solid #00ff37;
}
.bad:hover {
  outline: 1px solid #ff0000;
}
